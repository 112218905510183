import { del, get, post, put } from '../api_helper';
import * as url from '../url_helper';
import { getApiConfig } from '../auth_helper';

//* get sheet by collection event id
export const getSheetByCollectionEventId = async (id, type) => {
  return await get(
    `${url.SHEET}?collectionEventId=${id}&employeeType=${type}`,
    getApiConfig()
  );
};

//* get sheet by collection event
export const getSheetByCollectionId = async (id) => {
  return await get(`${url.SHEET}?collectionEventId=${id}`, getApiConfig());
};

//* get sheet
export const getSheetData = async (employeeId, collectionEventId) => {
  return await get(
    `${url.SHEET}?employeeId=${employeeId}&collectionEventId=${collectionEventId}`,
    getApiConfig()
  );
};

export const getSheet = async (id) => {
  return await get(`${url.SHEET}?_id=${id}`, getApiConfig());
};

//* create sheet
export const createEmployeeSheet = async (data) => {
  return await post(`${url.SHEET}`, data, getApiConfig());
};

//* handle update role wise sheet
export const updateStatusSheet = async (id, data) => {
  return await put(`${url.SHEET}/${id}`, data, getApiConfig());
};

//* create sheet
export const handleApproveRejectActivity = async (data) => {
  return await put(`${url.SHEET}/bulk-edit`, data, getApiConfig());
};

//* Delete sheet
export const deleteSheet = async (id) => {
  return await del(`${url.SHEET}/${id}`, getApiConfig());
};
