class employeForm {
  constructor() {
    this.employeFormArray = [
      {
        name: 'employeeCode',
        placeholder: 'Employee Code',
        inputControl: true,
        type: 'text',
        functionsHandel: {},
        size: 3,
        disabled: false,
        validetion: [],
        label: 'Employee Code',
        required: true,
      },
      {
        name: 'employeeName',
        placeholder: 'Employee Name',
        inputControl: true,
        type: 'text',
        functionsHandel: {},
        size: 3,
        disabled: false,
        validetion: [],
        label: 'Employee Name',
        required: true,
      },
      {
        name: 'email',
        placeholder: 'Email',
        inputControl: true,
        type: 'email',
        functionsHandel: {},
        size: 3,
        disabled: false,
        validetion: [],
        label: 'Email',
        required: true,
      },
      {
        name: 'shift',
        placeholder: 'Select Shift Type',
        inputControl: true,
        type: 'Select',
        functionsHandel: {},
        size: 3,
        label: 'Shift Type',
        options: [
          {
            label: 'Shift',
            value: 'Shift',
          },
          {
            label: 'General',
            value: 'General',
          },
        ],
        disabled: false,
        validetion: [],
        required: true,
      },
      {
        name: 'level',
        placeholder: 'Level',
        inputControl: true,
        type: 'text',
        functionsHandel: {},
        size: 3,
        disabled: false,
        validetion: [],
        label: 'Level',
      },
      {
        name: 'grade',
        placeholder: 'Grade',
        inputControl: true,
        type: 'text',
        functionsHandel: {},
        size: 3,
        disabled: false,
        validetion: [],
        label: 'Grade',
      },
      {
        name: 'jobTitle',
        placeholder: 'Job Title',
        inputControl: true,
        type: 'text',
        functionsHandel: {},
        size: 3,
        disabled: false,
        validetion: [],
        label: 'Job Title',
      },
      {
        name: 'jobBand',
        placeholder: 'Job Band',
        inputControl: true,
        type: 'text',
        functionsHandel: {},
        size: 3,
        disabled: false,
        validetion: [],
        label: 'Job Band',
      },
      {
        name: 'location',
        placeholder: 'location',
        inputControl: true,
        type: 'text',
        functionsHandel: {},
        size: 3,
        disabled: false,
        validetion: [],
        label: 'location',
      },
      {
        name: 'businessUnit',
        placeholder: 'Business Unit',
        inputControl: true,
        type: 'text',
        functionsHandel: {},
        size: 3,
        disabled: false,
        validetion: [],
        label: 'Business Unit',
      },
      {
        name: 'designation',
        placeholder: 'Designation',
        inputControl: true,
        type: 'text',
        functionsHandel: {},
        size: 3,
        disabled: false,
        validetion: [],
        label: 'Designation',
      },
      {
        name: 'position',
        placeholder: 'Position',
        inputControl: true,
        type: 'text',
        functionsHandel: {},
        size: 3,
        disabled: false,
        validetion: [],
        label: 'Position',
      },
      {
        name: 'role',
        placeholder: 'Role',
        inputControl: true,
        type: 'text',
        functionsHandel: {},
        size: 3,
        disabled: false,
        validetion: [],
        label: 'Role',
      },
      {
        name: 'roleDescription',
        placeholder: 'Role Description',
        inputControl: true,
        type: 'text',
        functionsHandel: {},
        size: 3,
        disabled: false,
        validetion: [],
        label: 'Role Description',
      },
      {
        name: 'category',
        placeholder: 'Category',
        inputControl: true,
        type: 'text',
        functionsHandel: {},
        size: 3,
        disabled: false,
        validetion: [],
        label: 'Category',
      },
      {
        name: 'center',
        placeholder: 'Center',
        inputControl: true,
        type: 'text',
        functionsHandel: (e) => {
          {
          }
        },
        size: 3,
        disabled: false,
        validetion: [],
        label: 'Center',
      },
      {
        name: 'hodName',
        placeholder: 'HOD Name',
        inputControl: true,
        type: 'text',
        functionsHandel: {},
        size: 3,
        disabled: false,
        validetion: [],
        label: 'HOD Name',
      },
      {
        name: 'hodTitle',
        placeholder: 'HOD Title',
        inputControl: true,
        type: 'text',
        functionsHandel: {},
        size: 3,
        disabled: false,
        validetion: [],
        label: 'HOD Title',
      },
      {
        name: 'headOfUnitName',
        placeholder: 'Head Of Unit Name',
        inputControl: true,
        type: 'text',
        functionsHandel: {},
        size: 3,
        disabled: false,
        validetion: [],
        label: 'Head Of Unit Name',
      },
      {
        name: 'headOfUnitTitle',
        placeholder: 'Head Of Unit Title',
        inputControl: true,
        type: 'text',
        functionsHandel: {},
        size: 3,
        disabled: false,
        validetion: [],
        label: 'Head Of Unit Title',
      },
      {
        name: 'department',
        placeholder: 'Department',
        inputControl: true,
        type: 'text',
        functionsHandel: {
          onChange: 'FunctionOnChange',
        },
        size: 3,
        disabled: false,
        validetion: [],
        label: 'Department',
      },
      {
        name: 'doj',
        // placeholder: '',
        inputControl: true,
        type: 'date',
        functionsHandel: {},
        size: 3,
        disabled: false,
        validetion: [],
        label: 'Date of Join',
      },
      {
        name: 'dob',
        // placeholder: '',
        inputControl: true,
        type: 'date',
        functionsHandel: {},
        size: 3,
        disabled: false,
        validetion: [],
        label: 'Date of Birth',
      },
      {
        name: 'gender',
        placeholder: 'Gender',
        inputControl: true,
        type: 'text',
        functionsHandel: {},
        size: 3,
        disabled: false,
        validetion: [],
        label: 'Gender',
      },
      {
        name: 'qualification',
        placeholder: 'Qualification',
        inputControl: true,
        type: 'text',
        functionsHandel: {},
        size: 3,
        disabled: false,
        validetion: [],
        label: 'Qualification',
      },

      {
        name: 'employeeType',
        placeholder: 'Select Employee Type',
        inputControl: true,
        type: 'Select',
        functionsHandel: {},
        size: 3,
        disabled: false,
        validetion: [],
        label: 'Employee Type',
        options: [
          {
            label: 'Individual',
            value: 'individual',
          },
          {
            label: 'Identical',
            value: 'identical',
          },
        ],
        required: true,
      },
      {
        name: 'teamId',
        placeholder: 'Select Team',
        inputControl: true,
        type: 'Select',
        functionsHandel: {},
        size: 3,
        disabled: false,
        validetion: [],
        label: 'Team',
        options: [],
        functionsHandel: {},
      },
    ];
  }

  getEmployeFormArray() {
    return this.employeFormArray;
  }
}
module.exports = employeForm; // Export the class to be used in other files

