import React, { useContext, useEffect } from 'react';
import {
  Button,
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@mui/material/Tooltip';
import CommentIcon from '@mui/icons-material/Comment';

import { useHistory } from 'react-router-dom';
import board from '../../../../src/assets/images/board.svg';
import EmployeeEffortLogComponent from '../../../components/EfforLogTable';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import ExpandableTableRow from '../../../components/ExpandableTableRow';
import PlaceholderImage from '../../../components/PlaceholderImage';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

import { STATUS_TEXT } from '../../../utils/utils';
import { Paths } from '../../../routes/paths';

import StatusBadge from '../../../components/Badge';
import { AuthContext } from '../../../components/AuthContextProvider';

const useStyles = makeStyles((theme) => ({
  table: {
    maxHeight: '50vh',
    width: '100%',
  },
  tableLong: {
    maxHeight: '70vh',
    width: '100%',
  },
  inlineIconButtons: {
    padding: '4px',
  },
  groupedButtons: {
    padding: '4px',
    fontWeight: '300',
    textTransform: 'uppercase',
    width: '60px',
  },
  disabledButton: {
    backgroundColor: theme.palette.primary.main,
  },
  expandableRow: {
    backgroundColor: theme.palette.grey[100],
  },
}));

const TableAssignRag = ({
  id,
  primaryButtonText,
  setCommentDialogOpen,
  secondaryButtonText,
  isActionable = () => null,
  tableHeads = [],
  isEditAction = false,
  uploadMode,
  team = [],
  isApproveReject = false,
  selected = [],
  setSelected,
  data = [],
  dataKeys = [],
  event,
  checkId = '',
  isSelectable = true,
  variant = 'table',
  disabled = false,
  isExpandable = false,
}) => {
  const classes = useStyles();
  const history = useHistory();
  let { user } = useContext(AuthContext);

  // if key is empty then remove that key from dataKeys and tableHeads
  dataKeys = dataKeys.filter((obj) => Object.keys(obj).length !== 0);
  tableHeads = tableHeads.filter((obj) => Object.keys(obj).length !== 0);

  useEffect(() => {
    switch (user.role) {
      case 'MANAGER':
        user['roleComment'] = 'poc';
        break;

      default:
        break;
    }
  }, []);

  //* handle check in particular employees
  const handleCheck = (id) => {
    const index = selected.indexOf(id);
    let updated = selected;
    if (index > -1) {
      updated.splice(index, 1);
    } else {
      updated = [...selected, id];
    }

    setSelected([...updated]);
  };

  //* handle select all in one click
  const handleCheckAll = () => {
    let updated;
    const actionableData = data.filter((item) => isActionable(item));
    if (selected.length !== actionableData.length) {
      updated = actionableData.map((item) => item[checkId]);
    } else {
      updated = [];
    }
    setSelected([...updated]);
  };

  //* check all action in selected
  const getActionableLength = (data) => {
    const updatedData = data ? data.filter((item) => isActionable(item)) : [];
    return updatedData.length;
  };

  return (
    <React.Fragment>
      <Paper className={`${classes.paper} my-3`}>
        <Grid
          container
          item
          justifyContent="space-between"
          alignItems="baseline"
          className="p-2">
          <Typography variant="body1">Total Items: {data.length}</Typography>

          {isApproveReject && (
            <Grid>
              <Button
                variant="outlined"
                size="large"
                disableElevation
                disabled={selected.length == 0 || disabled}
                onClick={() =>
                  setCommentDialogOpen({
                    isComment: true,
                    value: 'Rejected',
                  })
                }>
                Reject
              </Button>
              <Button
                className="mx-3"
                variant="contained"
                size="large"
                disableElevation
                disabled={selected.length == 0 || disabled}
                onClick={() =>
                  setCommentDialogOpen({ isComment: true, value: 'Approved' })
                }
                color="primary">
                Approve
              </Button>
            </Grid>
          )}
        </Grid>
        <TableContainer
          classes={{
            root: classes[variant],
          }}>
          <Table
            stickyHeader
            aria-labelledby="tableTitle"
            size="medium"
            aria-label={id}>
            <TableHead>
              <TableRow>
                {isSelectable && (
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={
                        selected.length &&
                        selected.length == getActionableLength(data)
                      }
                      onChange={() => handleCheckAll()}
                      disabled={
                        (user.role === 'MANAGER' && !event?.isManagerExists) ||
                        disabled
                      }
                      inputProps={{ 'aria-label': 'select all' }}
                    />
                  </TableCell>
                )}
                {tableHeads.map((headCell, index) => (
                  <TableCell
                    key={index}
                    align={headCell.align ? headCell.align : 'left'}>
                    {headCell.label}
                  </TableCell>
                ))}
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => {
                const isItemSelected = selected?.includes(row[checkId]);
                const isActionableItem = isActionable(row);
                row['startDate'] = new Date(row.startDate).toLocaleDateString();
                row['endDate'] = new Date(row.endDate).toLocaleDateString();

                return (
                  <ExpandableTableRow
                    isExpandable={isExpandable}
                    expandComponent={
                      isExpandable && (
                        <TableCell
                          colSpan={6}
                          className={classes.expandableRow}>
                          <EmployeeEffortLogComponent userId={row['_id']} />
                        </TableCell>
                      )
                    }
                    hover
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={index}
                    selected={isItemSelected}>
                    {isSelectable && (
                      <TableCell padding="checkbox">
                        <Checkbox
                          disabled={
                            (user.role === 'MANAGER' &&
                              !event?.isManagerExists) ||
                            !isActionableItem
                          }
                          checked={isItemSelected}
                          onClick={(event) => handleCheck(row[checkId])}
                        />
                      </TableCell>
                    )}
                    {dataKeys.map((item, index) => {
                      if (typeof row[item.id] === 'boolean') {
                        row[item.id] = row[item.id] ? 'Yes' : 'No';
                      }

                      const approvalComment =
                        row[
                        `${user?.roleComment?.toLowerCase()}ApprovalComments`
                        ]?.[
                        row[
                          `${user?.roleComment?.toLowerCase()}ApprovalComments`
                        ]?.length - 1
                        ];
                      const rejectComment =
                        row[
                        `${user?.roleComment?.toLowerCase()}RejectedComments`
                        ]?.[
                        row[
                          `${user?.roleComment?.toLowerCase()}RejectedComments`
                        ]?.length - 1
                        ];

                      return (
                        <TableCell
                          key={index}
                          // onClick={() => history.push('/manager/employee/' + row['userId'])}
                          align={
                            item.align
                              ? item.align
                              : typeof row[item.id] !== 'string'
                                ? 'center'
                                : 'center'
                          }>
                          {/* manage comment field */}
                          {item.id === 'comment' ? (
                            <Tooltip
                              style={{ cursor: 'pointer' }}
                              title={
                                <span>
                                  {row[
                                    `${user?.role?.toLowerCase()}ApprovalStatus`
                                  ] === STATUS_TEXT.APPROVED
                                    ? approvalComment
                                    : row[
                                      `${user?.role?.toLowerCase()}ApprovalStatus`
                                    ] === STATUS_TEXT.REJECTED
                                      ? rejectComment
                                      : '-'}
                                </span>
                              }
                              arrow
                              placement="top">
                              <CommentIcon />
                            </Tooltip>
                          ) : (
                            <StatusBadge row={row[item.id]} />
                          )}
                        </TableCell>
                      );
                    })}

                    <React.Fragment>
                      <TableCell
                        key={`actions-${id}`}
                        style={{ cursor: 'pointer', textAlign: 'center' }}>
                        {isEditAction ? (
                          <BorderColorIcon
                            onClick={() => {
                              history.push({
                                pathname: `/${user.role.toLowerCase()}/create-event/${row.enterpriseId._id
                                  }/${row._id}`,
                              });
                            }}
                          />
                        ) : (
                          <RemoveRedEyeIcon
                            color="primary"
                            fontSize="small"
                            onClick={() =>
                              history.push({
                                pathname: `${user?.role == 'POC'
                                  ? Paths.pocRag
                                  : Paths.managerAssignRagSelected
                                  }/${row._id}`,
                                state: event,
                              })
                            }
                          />
                        )}
                      </TableCell>
                    </React.Fragment>
                  </ExpandableTableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      {data?.length == 0 && !uploadMode ? (
        <PlaceholderImage
          image={board}
          messsage={'No records found'}
          width="25%"
        />
      ) : null}
    </React.Fragment>
  );
};

export default TableAssignRag;
