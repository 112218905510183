import { get, post } from '../api_helper';
import * as url from '../url_helper';
import { getApiConfig } from '../auth_helper';

//* create sub process
export const createSubProcess = async (data) => {
  return await post(`${url.SUB_PROCESS}`, data, getApiConfig());
};

//* get all sub process by employee id
export const getAllSubProcess = async (id) => {
  return await get(`${url.SUB_PROCESS}?employeeId=${id}`, getApiConfig());
};

//* get sub process by id
export const getSubProcess = async (id) => {
  return await get(`${url.SUB_PROCESS}?_id=${id}`, getApiConfig());
};
