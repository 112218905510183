import React, { useContext, useEffect, useState } from 'react';
import SecondaryLayout from './SecondaryLayout';
import { useLocation, useParams } from 'react-router-dom';
import {
  Divider,
  Grid,
  Paper,
  Typography,
  makeStyles,
  Button,
  Collapse,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  ButtonGroup,
} from '@material-ui/core';
import TextWithDivider from './TextWithDivider';
import Message from './Message';
import { ChevronRight, ExpandLess, ExpandMore } from '@material-ui/icons';

import { Table } from 'reactstrap';
import { getSheet, updateStatusSheet } from '../helper/backendHelper/sheet';
import { useHistory } from 'react-router-dom';
import { AuthContext } from './AuthContextProvider';
import Layout from './Layout';
import { getProcessBySheet } from '../helper/backendHelper/process';
import {
  getActivitiesByProcessId,
  updateAssignRag,
} from '../helper/backendHelper/activities';
import board from '../assets/images/board.svg';
import { RAG_VALUE, STATUS_TEXT } from '../utils/utils';
import PlaceholderImage from './PlaceholderImage';

const useStyles = makeStyles(() => ({
  secondaryContainer: {
    height: '80vh',
    flexGrow: 1,
  },
}));

const RoleActivity = () => {
  const { id } = useParams();
  const event = useLocation().state;
  const { user } = useContext(AuthContext);
  const history = useHistory();
  const classes = useStyles();
  const [selectedSubProcess, setSelectedSubProcess] = useState(null);
  const [loading, setLoading] = useState(false);
  const [activities, setActivities] = useState([]);
  const [open, setOpen] = useState({ 0: true });
  const [process, setProcess] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [comments, setComments] = useState([]);
  const [tempCommentById, setTempCommentById] = useState({});
  const [selectedIndex, setSelectedIndex] = useState({
    index: 0,
    nestedEl: 0,
  });

  useEffect(() => {
    if (id) {
      fetchProcessById(id);
    }
  }, []);

  //* fetch process by id
  const fetchProcessById = async (id) => {
    try {
      setLoading(true);
      let process = await getProcessBySheet(id);
      process = process?.payload?.data;
      setProcess(process);

      const sheet = await getSheet(id);
      user['managerApprovalStatus'] =
        sheet?.payload?.data[0].managerApprovalStatus;
      user['pocApprovalStatus'] = sheet?.payload?.data[0].pocApprovalStatus;
      user['managerSubmissionStatus'] = sheet?.payload?.data[0].managerSubmissionStatus;

      //* get first process when refresh the page
      let getFirstProcessSubProcess = process[0].subProcess[0];
      getFirstProcessSubProcess['process'] = process[0].name;
      getFirstProcessSubProcess['subProcessId'] = getFirstProcessSubProcess._id;

      await getActivitiesBySubProcessId(getFirstProcessSubProcess);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      Message.error(error?.response?.data?.message);
    }
  };

  //* fetch all Activities
  const getActivitiesBySubProcessId = async (data) => {
    setSelectedSubProcess(data);
    try {
      setLoading(true);
      let response = await getActivitiesByProcessId(data?.subProcessId);
      response = response.payload?.data;
      setActivities(response);
      setLoading(false);
    } catch (error) {
      Message.error(error?.response?.data?.message);
    }
  };

  const isActiveRAGButton = (btnVal, ragVal) => {
    return btnVal === ragVal;
  };

  //* handle input change on rag
  const handleInputChange = (rag, row) => {
    const isExists = selectedData.some((item) => item._id == row._id);
    if (!isExists) {
      setSelectedData([...selectedData, { ragValue: rag, _id: row._id }]);
    }
  };

  //* bulk update all activity of rag
  const handleSaveAllActivityRag = async (isSave) => {
    let string = JSON.stringify(tempCommentById);
    string = JSON.parse(string);

    for (let key in string) {
      selectedData.push({ _id: key, comment: string[key] });
    }
    try {
      setLoading(true);
      await handleStatus(isSave);
      if (selectedData) {
        const response = await updateAssignRag({
          activities: selectedData,
        });
        Message.success(response?.message);
        setSelectedData([]);
        setComments({});
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  //* handle  status
  const handleStatus = async (isSaveSubmit) => {
    try {
      await updateStatusSheet(id, {
        pocId: user.pocId,
        ...(user.role === 'MANAGER' && isSaveSubmit === 'save'
          ? { managerSubmissionStatus: STATUS_TEXT.IN_PROGRESS }
          : user.role === 'MANAGER' && isSaveSubmit === 'submit'
            ? {
              managerSubmissionStatus: STATUS_TEXT.SUBMITTED,
              pocApprovalStatus: STATUS_TEXT.IN_PROGRESS,
            }
            : {
              managerApprovalStatus: STATUS_TEXT.APPROVED,
              pocApprovalStatus: STATUS_TEXT.APPROVED,
            }),
      });
    } catch (error) { }
  };


  return (
    <Layout loading={loading}>
      <SecondaryLayout>
        <Grid container className="pl-4 pr-2">
          <Grid item lg={9} md={8} className="pr-4 pt-4">
            <TextWithDivider>
              <Typography variant="h3" color="textPrimary">
                Assign RAG
              </Typography>
            </TextWithDivider>
          </Grid>
          <Grid container className={`${classes.secondaryContainer} pr-4 mt-3`}>
            <Grid item container lg={2} md={3} className="h-100">
              <Paper style={{ width: '95%' }}>
                <List
                  component="nav"
                  aria-labelledby={`${id}-subheader`}
                  subheader={
                    <ListSubheader component="div" id={`${id}-subheader`}>
                      {'Log List'}
                    </ListSubheader>
                  }
                  className={classes.root}>
                  <Divider />
                  {process?.map((item, index) => (
                    <React.Fragment key={`${id}_${index}`}>
                      <ListItem
                        button
                        onClick={() =>
                          setOpen({ ...open, [index]: !open[index] })
                        }>
                        {open[index] ? <ExpandLess /> : <ExpandMore />}
                        <ListItemText primary={item.name} />
                      </ListItem>
                      {item['subProcess'].map((nestedItem, nestedIndex) => (
                        <Collapse
                          in={open[index]}
                          timeout="auto"
                          unmountOnExit
                          key={`${id}_${nestedIndex}`}>
                          <List component="div" disablePadding>
                            <ListItem
                              button
                              onClick={async () => {
                                getActivitiesBySubProcessId({
                                  process: item.name,
                                  processId: item._id,
                                  subProcess: nestedItem.name,
                                  subProcessId: nestedItem._id,
                                });
                                setSelectedIndex({
                                  index: index,
                                  nestedEl: nestedIndex,
                                });
                              }}
                              selected={
                                selectedIndex.index == index
                                  ? nestedIndex == selectedIndex.nestedEl
                                  : ''
                              }>
                              <ListItemText
                                className="ml-4"
                                primary={nestedItem.name}
                              />
                            </ListItem>
                          </List>
                        </Collapse>
                      ))}
                      <Divider />
                    </React.Fragment>
                  ))}
                </List>
              </Paper>
            </Grid>
            <Grid item xs>
              <Paper className="pl-3 pt-3 h-100">
                {selectedSubProcess && selectedSubProcess.process && (
                  <React.Fragment>
                    <Grid container className="pb-1">
                      <Grid item xs={6} className="d-flex">
                        <Typography variant="body1" color="textSecondary">
                          {selectedSubProcess.process}
                        </Typography>
                        <ChevronRight
                          color="disabled"
                          className="ml-1 mr-1"
                          style={{ marginTop: '2px' }}
                        />
                        <Typography variant="body1">
                          {selectedSubProcess.name}
                        </Typography>
                      </Grid>
                      {(event?.isManagerExists && user.role === 'MANAGER' &&
                        user?.managerApprovalStatus === STATUS_TEXT.APPROVED && user?.managerSubmissionStatus !== STATUS_TEXT.SUBMITTED) ||
                        (user.pocApprovalStatus == STATUS_TEXT.APPROVED &&
                          user.role == 'POC') ? (
                        <Grid xs={6} className="d-flex justify-content-end">
                          <Button
                            variant="outlined"
                            size="large"
                            disabled={!selectedData.length || false}

                            onClick={() => handleSaveAllActivityRag('save')}>
                            Save Progress
                          </Button>
                          <Button
                            className="mx-3"
                            variant="contained"
                            size="large"
                            onClick={async () => {
                              await handleSaveAllActivityRag('submit');
                              history.goBack();
                            }}
                            color="primary">
                            Submit
                          </Button>
                        </Grid>
                      ) : null}
                    </Grid>
                    <Divider />
                  </React.Fragment>
                )}

                {/* list activity */}

                <TableContainer className="mt-3">
                  <Table
                    aria-label="log-effort-table"
                  // size={isSubmitted ? 'medium' : 'small'}
                  >
                    <TableHead>
                      <TableRow>
                        {(event?.isManagerExists &&
                          user?.managerApprovalStatus == STATUS_TEXT.APPROVED &&
                          user.role == 'MANAGER') ||
                          (user.pocApprovalStatus == STATUS_TEXT.APPROVED &&
                            user.role == 'POC') ? (
                          <>
                            <TableCell>Activity</TableCell>
                            <TableCell>Rag</TableCell>
                            {user.role !== 'POC' && (
                              <TableCell>Comments</TableCell>
                            )}
                          </>
                        ) : (
                          <>
                            <TableCell>Activity</TableCell>
                            <TableCell>Application used</TableCell>
                            <TableCell>Individual Volume</TableCell>
                            <TableCell>hours & minutes</TableCell>
                            <TableCell>Total Hours</TableCell>
                          </>
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {activities?.map((row, index) => (
                        <TableRow key={row._id || index}>
                          {(event?.isManagerExists &&
                            user?.managerApprovalStatus ==
                            STATUS_TEXT.APPROVED &&
                            user.role == 'MANAGER') ||
                            (user.pocApprovalStatus == STATUS_TEXT.APPROVED &&
                              user.role == 'POC') ? (
                            <>
                              <TableCell style={{ width: '20%' }}>
                                <h6>{row.name ? row.name : '-'}</h6>
                              </TableCell>
                              <TableCell style={{ width: '20%' }}>
                                <RAGActions
                                  row={row}
                                  id={row._id}
                                  classes={classes}
                                  // handleButtonClick={(ragValue) => {
                                  //   handlePrimaryButtonClick([row.id], ragValue);
                                  // }}
                                  handleInputChange={handleInputChange}
                                  isActiveRAGButton={isActiveRAGButton}
                                  disabled={false}
                                />
                              </TableCell>
                              {user.role !== 'POC' && (
                                <TableCell style={{ width: '20%' }}>
                                  <TextField
                                    type="text"
                                    onChange={(e) => {
                                      const newComment = e.target.value;

                                      setTempCommentById({
                                        ...tempCommentById,
                                        [row._id]: newComment,
                                      });

                                      // Update the 'comments' field of the corresponding 'row'
                                      const updatedRow = { ...row };
                                      updatedRow.comment =
                                        updatedRow.comment || [];

                                      updatedRow.comment = newComment;
                                      // Example using a state hook
                                      const updatedActivities = [...activities];
                                      updatedActivities[index] = updatedRow;

                                      setActivities(updatedActivities);
                                    }}
                                    defaultValue={row?.comment}
                                    placeholder="Enter Comment here"
                                  />
                                </TableCell>
                              )}
                            </>
                          ) : (
                            <>
                              <TableCell style={{ width: '20%' }}>
                                <h6>{row.name ? row.name : '-'}</h6>
                              </TableCell>
                              <TableCell style={{ width: '20%' }}>
                                <h6>
                                  {row.applicationUsed
                                    ? row.applicationUsed
                                    : '-'}
                                </h6>
                              </TableCell>
                              <TableCell style={{ width: '20%' }}>
                                <h6>
                                  {row.individualVolume
                                    ? row.individualVolume
                                    : 0}
                                </h6>
                              </TableCell>
                              <TableCell style={{ width: '20%' }}>
                                <h6>{`${row.hours ? row.hours : '00'} : ${row.minutes ? row.minutes : '00'
                                  }`}</h6>
                              </TableCell>
                              <TableCell style={{ width: '20%' }}>
                                <h6>{row.totalHours ? row.totalHours : '-'}</h6>
                              </TableCell>
                            </>
                          )}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                {activities?.length == 0 && (
                  <PlaceholderImage
                    image={board}
                    messsage={'No records found'}
                    width="25%"
                  />
                )}
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </SecondaryLayout>
    </Layout>
  );
};

const RAGActions = ({
  row,
  id,
  classes,
  handleButtonClick,
  handleInputChange,
  isActiveRAGButton,
  disabled = false,
}) => {
  const [currentSelected, setCurrentSelected] = useState(row.ragValue);
  const isPreset = row.ragValue ? true : false;

  // const exist = data.some((item)=> item._id == row._id)

  return (
    <React.Fragment>
      <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
        <ButtonGroup
          size="small"
          variant="contained"
          disableElevation
          aria-label="RAG button group">
          <Button
            variant="contained"
            size="small"
            disableElevation
            classes={{
              root: classes.groupedButtons,
            }}
            color={
              isActiveRAGButton(RAG_VALUE.RED, currentSelected)
                ? 'primary'
                : 'default'
            }
            onClick={() => {
              setCurrentSelected(RAG_VALUE.RED);
              handleInputChange(RAG_VALUE.RED, row);
            }}>
            Red
          </Button>
          <Button
            variant="contained"
            disableElevation
            size="small"
            classes={{
              root: classes.groupedButtons,
            }}
            color={
              isActiveRAGButton(RAG_VALUE.AMBER, currentSelected)
                ? 'primary'
                : 'default'
            }
            onClick={() => {
              setCurrentSelected(RAG_VALUE.AMBER);
              handleInputChange(RAG_VALUE.AMBER, row);
            }}>
            Amber
          </Button>
          <Button
            variant="contained"
            disableElevation
            size="small"
            classes={{
              root: classes.groupedButtons,
            }}
            color={
              isActiveRAGButton(RAG_VALUE.GREEN, currentSelected)
                ? 'primary'
                : 'default'
            }
            onClick={() => {
              setCurrentSelected(RAG_VALUE.GREEN);
              handleInputChange(RAG_VALUE.GREEN, row);
            }}>
            Green
          </Button>
        </ButtonGroup>
      </div>
    </React.Fragment>
  );
};

export default RoleActivity;
