import { Grid, IconButton, Paper, Typography } from '@material-ui/core';
import React from 'react';
import { colors } from '../../../../theme/colors';
import EditIcon from '@mui/icons-material/Edit';
import { useHistory } from 'react-router';
import { Paths } from '../../../../routes/paths';

const OrganisationCard = ({
  name,
  yearOfIncorporation = 'unkknown',
  location = 'unknown',
  organizationId,
  eventsCompleted = [],
  totalEvents = [],
  eventsInProgress = [],
  val,
}) => {
  const history = useHistory();

  return (
    <Paper className="mx-4 p-3 my-4" elevation={0}>
      <Grid container>
        <Grid item xs={3}>
          <Typography
            style={{ color: colors.grey[200] }}
            align="left"
            variant="subtitle2">
            {name}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography className="font-weight-bold" align="left" variant="body1">
            {`${eventsCompleted} completed`}{' '}
            <span style={{ color: colors.secondary.main }}>⬤</span>{' '}
            {`${eventsInProgress}`} in-progress
          </Typography>
          <Typography
            align="left"
            style={{ color: colors.grey[200] }}
            variant="subtitle2">
            # Events Collected
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography align="left" variant="body1">
            {yearOfIncorporation}
          </Typography>
          <Typography
            align="left"
            style={{ color: colors.grey[200] }}
            variant="subtitle2">
            Year of incorporation
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography variant="body1">{location}</Typography>
          <Typography style={{ color: colors.grey[200] }} variant="subtitle2">
            Location
          </Typography>
        </Grid>
        <Grid container justifyContent="flex-end" item xs={1}>
          <IconButton
            onClick={() => {
              history.push(`${Paths.organization}/${organizationId}`, {
                state: val,
              });
            }}>
            <EditIcon />
          </IconButton>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default OrganisationCard;
