import React, { useContext, useEffect, useState } from 'react';
import Layout from '../../../components/Layout';
import { Step, StepButton, Stepper, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import TextWithDivider from '../../../components/TextWithDivider';
import { AuthContext } from '../../../components/AuthContextProvider';
import SecondaryLayout from '../../../components/SecondaryLayout';
import CompanyDetails from './CompanyDetails';

import useFetch from '../../../hooks/useFetch';
import UploadTemplates from './UploadTemplates';
import { getEnterpriseDataById } from '../../../helper/backendHelper/poc';
import Message from '../../../components/Message';
import { updateEnterprise } from '../../../helper/backendHelper/enterprise';
import { useHistory } from 'react-router-dom';
import { Paths } from '../../../routes/paths';

function getSteps() {
  return ['Company Details', 'Upload Templates'];
}

const Settings = () => {
  let { user } = useContext(AuthContext);
  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [enterpriseData, setEnterpriseData] = useState({});
  const history = useHistory();

  useEffect(() => {
    fetchEnterpriseDataByPocId();
  }, []);

  //* fetch enterpriseData by pocId
  const fetchEnterpriseDataByPocId = async () => {
    try {
      setLoading(true);
      const response = await getEnterpriseDataById(user._id);
      setEnterpriseData(response?.payload);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      Message.error(error?.response?.data?.message);
    }
  };

  const steps = getSteps();

  const handleStep = (step) => {
    setActiveStep(step);
  };

  //* update company details in enterprise
  const handleCompanyDetailsUpdates = async (data) => {
    try {
      setLoading(true);
      data['isPOCDataAdded'] = true;
      const response = await updateEnterprise(data._id, data);
      Message.success(response.message);
      history.push(Paths.pocManagers);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      Message.error(error?.response?.data?.message);
    }
  };

  return (
    <Layout loading={loading}>
      <SecondaryLayout>
        <Grid container className="pl-4 pr-2">
          <Grid item md={12} className="pr-4 pt-4">
            <TextWithDivider>
              <Typography variant="h3" color="textPrimary">
                Settings
              </Typography>
            </TextWithDivider>
            <Grid container item className="mt-3" justifyContent="center">
              <Grid item md={6}>
                <Stepper nonLinear activeStep={activeStep}>
                  {steps.map((label, index) => (
                    <Step key={label}>
                      <StepButton
                        onClick={() => handleStep(index)}
                        completed={false}>
                        {label}
                      </StepButton>
                    </Step>
                  ))}
                </Stepper>
              </Grid>
              <Grid item md={10} className="mt-4">
                {activeStep === 0 ? (
                  <CompanyDetails
                    handleCompanyDetailsUpdates={handleCompanyDetailsUpdates}
                    data={enterpriseData?.enterpriseId}
                  />
                ) : activeStep === 1 ? (
                  <UploadTemplates
                    setLoading={setLoading}
                    id={enterpriseData?.enterpriseId?._id}
                    enterpriseData={enterpriseData}
                  />
                ) : null}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </SecondaryLayout>
    </Layout>
  );
};

export default Settings;
