import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { AuthContext } from '../AuthContextProvider';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core';
import ExitToApp from '@material-ui/icons/ExitToApp';
import InfoIcon from '@material-ui/icons/InfoRounded';
import { colors } from '../../theme/colors';
import useFetch from '../../hooks/useFetch';
import { fetchEnterpriseById } from '../../operations/enterprise/index';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    position: 'fixed',
    top: 0,
    width: '100%',
    zIndex: 999,
    background: colors.common.white,
  },
  title: {
    flexGrow: 1,
  },
}));

export default function Appbar() {
  const history = useHistory();
  const classes = useStyles();
  const { user, setSave } = useContext(AuthContext);
  const [isOpen, setIsOpen] = useState(false);
  const { data: enterprise } = useFetch(
    fetchEnterpriseById,
    user.enterpriseId || ''
  );

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar disableGutters>
          {enterprise?.logoUrl ? (
            <div className={classes.title}>
              <img
                style={{ height: '50px' }}
                src={enterprise.logoUrl}
                alt={enterprise.name}
              />
            </div>
          ) : (
            <Typography
              variant="h6"
              className={classes.title}
              color="textPrimary">
              {enterprise?.name}
            </Typography>
          )}
          {user.role?.toUpperCase() !== 'ADMIN' && (
            <IconButton
              className="ml-2"
              color="primary"
              onClick={() => setIsOpen(true)}
              aria-label="menu">
              <InfoIcon />
            </IconButton>
          )}
          <Typography variant="subtitle2" color="textPrimary">
            {user?.role}
          </Typography>
          <Typography variant="subtitle2" color="textPrimary">
            • {user?.email}
          </Typography>
          <IconButton
            className="ml-2"
            color="primary"
            onClick={() => {
              localStorage.clear();
              setSave((preSaved) => !preSaved);
              history.push('/');
            }}
            aria-label="menu">
            <ExitToApp />
          </IconButton>
        </Toolbar>
      </AppBar>
      <InfoDialog
        open={isOpen}
        handleClose={() => setIsOpen(false)}
        role={user?.role}
      />
    </div>
  );
}

const InfoDialog = ({ role, open, handleClose }) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={10}>
            <Typography variant="h6" style={{ textTransform: 'capitalize' }}>
              {role?.toLowerCase()} Workload
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Button variant="text" color="primary" onClick={handleClose}>
              Close
            </Button>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        {role?.toUpperCase() === 'EMPLOYEE' && <EmployeeInfoDialog />}
        {role?.toUpperCase() === 'POC' && <SPOCInfoDialog />}
        {role?.toUpperCase() === 'MANAGER' && <ManagerInfoDialog />}
      </DialogContent>
      <DialogActions></DialogActions>
    </Dialog>
  );
};

const EmployeeInfoDialog = () => {
  return (
    <div>
      <p>
        Greetings! Our ongoing efforts in organizational effectiveness has led
        us to study our organization’s workload and volumes managed by teams to
        improve talent utilization and perform better job design. The end
        objective is to arrive at the total volume and workload managed by team
        members (Managers and below performing similar or different roles)
        across Divisions / Corporate / Central functions in a given financial
        year. We request your support in ensuring the information you provide is
        both accurate and comprehensive.
      </p>
      <p>Data to be entered by employees</p>
      <p>
        Based on by team members performing similar or individual roles (based
        on team structure as per organization chart and job description).
      </p>
      <iframe
        src="https://drive.google.com/file/d/1mJ_rf3NrLvVANu616uFWjB8QLKs3aWCW/preview"
        width="100%"
        height="480"
        allow="autoplay"
      />
      <ul>
        <li>
          Similar Roles – validate process, sub-process, activities, application
          used to perform the activity and{' '}
          <span style={{ textDecoration: 'underline' }}>
            enter frequency, time taken (in hours) and volumetric information
          </span>
          (number of transactions performed against that activity) e.g. number
          of invoices handled by the individual
        </li>
        <li>
          Individual Roles –{' '}
          <span style={{ textDecoration: 'underline' }}>
            Enter details of process, sub-process, activities, application used
            to perform the activity and enter frequency, time taken (in hours)
            and volumetric information
          </span>{' '}
          (number of transactions performed against that activity) e.g. number
          of invoices handled by the individual
        </li>
      </ul>
      <p>
        This data is captured in a standard format to calculate individual
        workload against “standard available hours in a given year”. We request
        your support in completing this work sheet and submitting it to your
        manager for reviews. Please feel free to reach your HR organization for
        any additional information
      </p>
    </div>
  );
};

const ManagerInfoDialog = () => {
  return (
    <div>
      <p>
        Greetings! Our ongoing efforts in organizational effectiveness has led
        us to study our organization’s workload and volumes managed by teams to
        improve talent utilization and perform better job design. The end
        objective is to arrive at the total volume and workload managed by team
        members (Managers and below performing similar or different roles)
        across Divisions / Corporate / Central functions in a given financial
        year. We request your support in validating information provided by team
        members to ensure it is both accurate and comprehensive.
      </p>
      <p>
        The data collection sheets are organized by the nature of roles team
        members perform.
      </p>
      <ul>
        <li>
          Individual Roles - Workload of team members performing different roles
          within the team, who are individually responsible for execution of
          different activities (individual contributor roles)
        </li>
        <li>
          Identical or Similar Roles - Workload of team members performing
          similar roles within the team, who share responsibilities in execution
          of similar activities and volumes
        </li>
      </ul>
      <iframe
        src="https://drive.google.com/file/d/1gIw7BE7bvappc-KDHOhSuWMDffLoAY9S/preview"
        width="100%"
        height="480"
        allow="autoplay"
      />
      <p>Data to be entered by Managers</p>
      <ul>
        <li>
          <b>
            Details of team members who perform individual or identical roles as
            in the XL template
          </b>
        </li>
        <li>
          <b>
            For similar roles, identify and assign one representative to enter
            details of{' '}
          </b>
          <span style={{ textDecoration: 'underline' }}>
            process, sub-process, activities, application used to perform the
            activity
          </span>
          . This will be used to collect data for the entire team
        </li>
        <li>
          Validate data provided by the employees and classify activities into
          Red, Amber and Green, defined as Non-value adding, Necessary but
          Non-value adding and Value adding based on definitions, adding Remarks
          where required{' '}
        </li>
      </ul>
      <RAGDescription />
      <iframe
        src="https://drive.google.com/file/d/1jM4Tf6F301UkvyPybKVbeFXBJLJx05DU/preview"
        width="100%"
        height="480"
        allow="autoplay"
      />
    </div>
  );
};

const SPOCInfoDialog = () => {
  return (
    <div>
      <p>
        Greetings! Our ongoing efforts in organizational effectiveness has led
        us to study the organization’s workload and volumes managed by teams to
        improve talent utilization and perform better job design. The end
        objective is to arrive at the total volume and workload managed by team
        members (Managers and below performing similar or different roles)
        across Divisions / Corporate / Central functions in a given financial
        year. We request your support in ensuring the information provided by
        Managers is both accurate and comprehensive.{' '}
      </p>
      <p>Data to be entered by Managers</p>
      <ul>
        <li>Details of managers across the organization</li>
        <li>
          Workload assumptions for shift and non-shift employees based on
          company HR policy – total number of working days, annual leaves,
          government or festival holidays for the year to calculate total number
          of productive days in a year
        </li>
        <li>
          Productive Hours per Shift – We typically use 6 Hours 30 minutes as
          productive hours in an 8 hour shift (as per international Labour
          Organization Standards that factor in fatigue, unplanned absenteeism)
        </li>
        <li>
          Validate data provided by Managers and activities that are classified
          into Red, Amber and Green, defined as Non-value adding, Necessary but
          Non-value adding and Value adding based on definitions, and reviewing
          Remarks
        </li>
      </ul>
      <p>
        The reports are organization-wide data and a summary of collective Red,
        Amber and Green activities as assigned by Managers (as per definitions
        below) to help you further perform analytics of volumetric and workload
        data.{' '}
      </p>
      <p>
        Please feel free to reach Arvind Raghavan ar@odob.in / +917708049666 for
        any additional information.
      </p>
      <iframe
        src="https://drive.google.com/file/d/18t6WafOJqozItJUTYiy7IzbiWtOG5_P7/preview"
        width="100%"
        height="480"
        allow="autoplay"
      />
      <RAGDescription />
      <iframe
        src="https://drive.google.com/file/d/1EKJovFM599JtR6KJSZNQ8vxOFboyhR4C/preview"
        width="100%"
        height="480"
        allow="autoplay"
      />
    </div>
  );
};

const RAGDescription = () => {
  return (
    <div>
      <p>
        <b>Definitions</b>
      </p>
      <p>
        <b>Red or Non-value adding</b> - These are redundant activities that are
        performed by team members, that contribute to cost and time without
        adding value and often appear owing to duplication in activities
        performed within teams or by different teams. These activities would
        need to be eliminated reassigned to respective departments in order to
        bring back focus on core activities
      </p>
      <p>
        <b>Amber or Necessary but Non-value adding </b>- These activities are
        necessary and are often time consuming owing to lack of process alacrity
        or automation. These activities need to be simplified / standardised or
        automated to reduce time and effort{' '}
      </p>
      <p>
        <b>Green or Value adding</b> – These activities support the
        organization’s overall long term strategic objectives that require focus
        and specialisation. Time spent on these activities need to be carefully
        studied, improved by further standardisation (where applicable) or
        automated to reduce time and effort
      </p>
    </div>
  );
};
