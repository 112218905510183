import { get, post } from '../api_helper';
import * as url from '../url_helper';
import { getApiConfig } from '../auth_helper';

//* create new process
export const createProcess = async (data) => {
  return await post(`${url.PROCESS}`, data, getApiConfig());
};

//* get all process
export const getAllProcess = async (id) => {
  return await get(`${url.PROCESS}?employeeId=${id}`, getApiConfig());
};

//* get all process
export const getProcess = async (id) => {
  return await get(`${url.PROCESS}?_id=${id}`, getApiConfig());
};

//* get process by sheet id
export const getProcessBySheet = async (id) => {
  return await get(`${url.PROCESS}?sheetId=${id}`, getApiConfig());
};

export const getAllActivitiesOnSheet = async (id) => {
  return await get(`${url.PROCESS}/sheet`, getApiConfig());
};
