import React from 'react';
import { ClickAwayListener, TextField } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

const CreatableSelect = ({
  id = 'creatable-selector',
  helperText,
  label,
  setInput,
  input,
  data = [],

  disabled = false,
}) => {
  const inputRef = React.useRef();

  return (
    <ClickAwayListener onClickAway={() => {}}>
      <Grid className={'w-100'}>
        <TextField
          id={`${id}-text-field`}
          label={label}
          value={input}
          inputRef={inputRef}
          onChange={(evt) => {
            setInput(evt.target.value);
          }}
          disabled={disabled}
          onClick={() => {
            inputRef.current.focus();
          }}
          className="w-100"
          InputProps={{
            autoComplete: 'off',
          }}
          helperText={helperText}
        />

        {/* {menuStatus && ((listItems && listItems.length) || input) && (
          <Paper
            classes={{
              root: classes.paper,
            }}
            style={{ width: inputRef?.current?.offsetWidth }}>
            <List component="nav" id="creatable-select-menu">
              {listItems && listItems.length
                ? listItems.map((option, index) => (
                    <React.Fragment key={index}>
                      <ListItem
                        button
                        onClick={() => {
                          setInput(option.name);
                          handleSelect(option);
                          closeMenu();
                        }}>
                        {option.name || ''}
                      </ListItem>
                    </React.Fragment>
                  ))
                : input && (
                    <ListItem
                      onClick={() => {
                        setInput(input);
                        handleAddNew(input);
                        closeMenu();
                      }}
                      button
                      key={'add-item'}>
                      <Typography
                        variant="body1"
                        component="span"
                        color="primary">
                        ADD
                      </Typography>
                      <Typography
                        variant="body1"
                        color="textPrimary"
                        className="ml-1">
                        {input}
                      </Typography>
                    </ListItem>
                  )}
            </List>
          </Paper>
        )} */}
      </Grid>
    </ClickAwayListener>
  );
};

export default CreatableSelect;
