import React, { useContext, useEffect, useState } from 'react';
import Layout from '../../../components/Layout';
import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import TextWithDivider from '../../../components/TextWithDivider';
import ProfileContainer from '../../../components/ProfileContainer';
import EmptyLogEffort from '../components/EmptyLogEffort';
import Tooltip from '@mui/material/Tooltip';
import CommentIcon from '@mui/icons-material/Comment';
import { AuthContext } from '../../../components/AuthContextProvider';
import { useHistory } from 'react-router-dom';
import { Paths } from '../../../routes/paths';
import { makeStyles } from '@material-ui/core/styles';
import { STATUS_TEXT } from '../../../utils/utils';
import DeleteIcon from '@material-ui/icons/DeleteOutlineOutlined';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import commonStyles from '../../../theme/commonStyles';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import { EFFORT_LOG_STATUS } from '../../../operations/constants';
import { getEmployee } from '../../../helper/backendHelper/employee';
import Message from '../../../components/Message';
import {
  createEmployeeSheet,
  deleteSheet,
  getSheetData,
} from '../../../helper/backendHelper/sheet';
import StatusBadge from '../../../components/Badge';

const useStyles = makeStyles((theme) => ({
  effortLogContainer: {
    backgroundColor: 'rgba(244, 245, 250, 0.4)',
  },
  statusContainer: {
    backgroundColor: theme.palette.common.white,
    border: 'solid 1px #d68d00',
    borderRadius: '4px',
  },
  editRoot: {
    backgroundColor: 'rgba(24, 123, 200, 0.1)',
  },
  separator: {
    height: '8px',
    width: '8px',
    borderRadius: '4px',
    backgroundColor: '#009d9d',
    display: 'inline-block',
  },
}));

const EmployeeHome = () => {
  let { user } = useContext(AuthContext);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [allEvents, setAllEvents] = useState([]);
  const [event, setEvent] = useState(null);
  const [effortLog, setEffortLog] = useState([]);

  const classes = useStyles();
  const commonClasses = commonStyles();

  useEffect(() => {
    fetchEmployeeEvents();
  }, []);

  //* fetch employee by events
  const fetchEmployeeEvents = async () => {
    try {
      const employeeEvents = await getEmployee(user._id);
      setAllEvents(employeeEvents?.payload?.data);
    } catch (error) {
      Message.error(error?.response?.data?.message);
    }
  };

  //*  fetch Sheet Data
  const fetchSheetData = async (data) => {
    try {
      const response = await getSheetData(
        data?._id,
        data?.collectionEventId?._id
      );
      setEffortLog(response?.payload?.data);
    } catch (error) {
      Message.error(error?.response?.data?.message);
    }
  };

  //* handle create new effort log
  const handleCreateNewLog = async () => {
    if (!event) {
      return Message.error('Please select collection event !');
    }
    try {
      setLoading(true);

      //* create employee sheet
      let response = await createEmployeeSheet({
        employeeId: event._id,
        enterpriseId: event?.enterpriseId?._id,
        collectionEventId: event?.collectionEventId?._id,
        ...(event?.teamId ? { teamId: event?.teamId } : {}),
        status: EFFORT_LOG_STATUS.NEW,
      });
      response = response?.payload;
      addLocalStorageOfEmployeeData(response);
      setLoading(false);
      pushToCreateLogEffort(response?._id);
    } catch (error) {
      setLoading(false);
    }
  };

  //* add local storage employee data
  const addLocalStorageOfEmployeeData = (data) => {
    data['employeeType'] = event?.employeeType;
    data['teamId'] = event?.teamId;
    data['isFlaggedUser'] = event?.isFlaggedUser;
    data['shift'] = event?.shift;
    data['collectionEventName'] = event?.collectionEventId?.name;
    data['collectionEventId'] = event?.collectionEventId?._id;
    data['enterpriseId'] = event?.enterpriseId?._id;

    localStorage.setItem('effortLog', JSON.stringify(data));
  };

  useEffect(() => {
    if (localStorage.getItem('effortLog')) {
      let effortLog = localStorage.getItem('effortLog');
      effortLog = JSON.parse(effortLog);
      pushToCreateLogEffort(effortLog?._id);
    }
  }, []);

  const pushToCreateLogEffort = (effortLogId) => {
    if (effortLogId) {
      history.push({
        pathname: `${Paths.logEffort}/${effortLogId}`,
        state: { effortLog },
      });
    }
  };

  const deleteLog = async (id) => {
    try {
      setLoading(true);
      const response = await deleteSheet(id);
      fetchSheetData(event);
      Message.success(response.message);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const isSubmitted = (item) => {
    return STATUS_TEXT.SUBMITTED === item?.status;
  };

  return (
    <Layout loading={loading}>
      <Paper>
        <Grid container className="pl-4 pr-2">
          <Grid container spacing={2} item md={6}></Grid>
          <Grid item lg={9} md={8} className="pr-4 pt-5">
            <TextWithDivider>
              <Typography variant="h3" color="textPrimary">
                Effort Log
              </Typography>
            </TextWithDivider>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel id="selectEnterprise">
                  Select Collection Of Events
                </InputLabel>

                <Select
                  labelId="selectEnterprise"
                  fullWidth
                  placeholder="Select Enterprise"
                  onChange={(e) => {
                    setEvent(e.target.value);
                    fetchSheetData(e.target.value);
                  }}>
                  {allEvents?.map((item) => (
                    <MenuItem value={item} key={item.name}>
                      {item.collectionEventId?.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {effortLog.length !== 0 ? (
              effortLog.map((item, index) => {
                if (item.managerApprovalStatus == STATUS_TEXT.APPROVED) {
                  item['approveComment'] =
                    item?.managerApprovalComments[
                    item?.managerApprovalComments?.length - 1
                    ];
                }
                if (item?.managerApprovalStatus == STATUS_TEXT.REJECTED) {
                  item['rejectComment'] =
                    item?.managerRejectedComments[
                    item?.managerRejectedComments?.length - 1
                    ];
                }
                return (
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    className={`${classes.effortLogContainer} p-3 pt-2 pb-2 mt-5`}>
                    <Grid>
                      <Typography variant="subtitle1">
                        {item.name || 0}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        className="mt-3">
                        {`Edited on ${new Date(
                          item.updatedAt
                        ).toLocaleDateString()}`}
                      </Typography>
                    </Grid>
                    <Grid>
                      <Typography variant="subtitle1">
                        {item.processCount || 0} Process
                        <div className={`${classes.separator} ml-2 mr-2`} />
                        {item.subProcessCount || 0} Subprocess
                        <div className={`${classes.separator} ml-2 mr-2`} />
                        {item.activityCount || 0} Activities
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        className="mt-3">
                        Total Effort Logged
                      </Typography>
                    </Grid>

                    <Grid>
                      <Typography variant="subtitle1">
                        <StatusBadge row={item.status} />
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        className="mt-2">
                        Employee Status
                      </Typography>
                    </Grid>
                    {item.managerApprovalStatus && (
                      <Grid>
                        <Typography variant="subtitle1">
                          <StatusBadge row={item.managerApprovalStatus} />
                        </Typography>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          className="mt-2">
                          Manager Status
                        </Typography>
                      </Grid>
                    )}

                    {item.managerApprovalStatus == STATUS_TEXT.APPROVED && (
                      <Grid>
                        <Typography
                          variant="body2"
                          className="mb-2 text-center">
                          <Tooltip
                            style={{ cursor: 'pointer' }}
                            title={<span>{item.approveComment}</span>}
                            arrow
                            placement="top">
                            <CommentIcon />
                          </Tooltip>
                        </Typography>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          className="mt-3">
                          Manager Comment
                        </Typography>
                      </Grid>
                    )}

                    {item.managerApprovalStatus == STATUS_TEXT.REJECTED && (
                      <Grid>
                        <Typography variant="body2" className="text-center">
                          <Tooltip
                            style={{ cursor: 'pointer' }}
                            title={<span>{item.rejectComment}</span>}
                            arrow
                            placement="top">
                            <CommentIcon fontSize="large" />
                          </Tooltip>
                        </Typography>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          className="mt-2">
                          Manager Comment
                        </Typography>
                      </Grid>
                    )}

                    <Grid>
                      <IconButton
                        aria-label="edit"
                        classes={{
                          root: classes.editRoot,
                        }}
                        onClick={() => {
                          item['employeeId'] = event._id;
                          addLocalStorageOfEmployeeData(item);
                          pushToCreateLogEffort(item._id);
                        }}>
                        {isSubmitted(item) ? (
                          <VisibilityOutlinedIcon
                            fontSize="small"
                            color="primary"
                          />
                        ) : (
                          <EditRoundedIcon fontSize="small" color="primary" />
                        )}
                      </IconButton>
                      {!isSubmitted(item) && (
                        <IconButton
                          classes={{
                            root: commonClasses.deleteRoot,
                          }}
                          className="ml-4"
                          aria-label="delete"
                          onClick={() => deleteLog(item._id)}>
                          <DeleteIcon
                            fontSize="small"
                            className={commonClasses.deleteIcon}
                          />
                        </IconButton>
                      )}
                    </Grid>
                  </Grid>
                );
              })
            ) : (
              <EmptyLogEffort handleCreateClick={handleCreateNewLog} />
            )}
          </Grid>
          <ProfileContainer />
        </Grid>
      </Paper>
    </Layout>
  );
};

export default EmployeeHome;
