import { get } from '../api_helper';
import * as url from '../url_helper';
import { getApiConfig } from '../auth_helper';

//* get all events
export const getAllTeam = async (collectionEventId) => {
  return await get(
    `${url.TEAM}?collectionEventId=${collectionEventId}`,
    getApiConfig()
  );
};
