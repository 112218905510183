import { Button, Grid, Typography } from '@material-ui/core';
import AddCircleOutline from '@material-ui/icons/AddCircleOutline';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import Layout from '../../../components/Layout';
import SecondaryLayout from '../../../components/SecondaryLayout';
import TextWithDivider from '../../../components/TextWithDivider';
import { Paths } from '../../../routes/paths';
import OrganisationCard from '../components/OrganisationCard';
import { getAllEnterprise } from '../../../helper/backendHelper/enterprise';
import Message from '../../../components/Message';
import { EventContext } from '../../../components/EventContextProvider';
import PlaceholderImage from '../../../components/PlaceholderImage';
import Board from '../../../../src/assets/images/board.svg';

const OrganisationList = () => {
  let { events } = useContext(EventContext);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [enterprises, setEnterprises] = useState([]);
  // const { data: enterprises = [], loading: enterpriseLoading = false } =
  // useFetch(fetchAllEnterprisesWithBatchData);

  useEffect(() => {
    fetchAllEnterprise();
  }, []);

  const fetchAllEnterprise = async () => {
    try {
      setLoading(true);
      const enterprise = await getAllEnterprise();
      setEnterprises(enterprise?.payload?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      Message.error(error?.response?.data?.message);
    }
  };

  return (
    <Layout loading={loading}>
      <SecondaryLayout>
        <div className="mx-5">
          <Grid container justifyContent="space-between" className="p-4">
            <Grid>
              <TextWithDivider>
                <Typography variant="h3" color="textPrimary">
                  Organisations
                </Typography>
              </TextWithDivider>
            </Grid>
            <Button
              onClick={() => {
                history.push(Paths.organization);
              }}
              disableElevation
              startIcon={<AddCircleOutline />}
              color="primary"
              variant="contained">
              Add New Organisation
            </Button>
          </Grid>
          {!enterprises.length ? (
            <div>
              <PlaceholderImage
                image={Board}
                messsage="No records available"
                width="300px"
              />
            </div>
          ) : (
            <>
              <Typography className="p-4" variant="h3">
                Organisation Details
              </Typography>
              {enterprises.map((el, idx) => (
                <OrganisationCard
                  organizationId={el._id}
                  yearOfIncorporation={el.yearOfIncorporation}
                  name={el.name}
                  location={el.location}
                  val={el}
                  key={idx}
                  eventsInProgress={el.noOfInProgressCollectionEvent}
                  eventsCompleted={el.noOfCompletedCollectionEvent}
                  totalEvents={el.totalEvents}
                />
              ))}
            </>
          )}
        </div>
      </SecondaryLayout>
    </Layout>
  );
};

export default OrganisationList;
