import React, { useContext, useState } from 'react';
import {
  Button,
  Collapse,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  makeStyles,
  Paper,
} from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { getSubProcess } from '../../helper/backendHelper/subProcesss';
import { AuthContext } from '../AuthContextProvider';
import { STATUS_TEXT } from '../../utils/utils';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    minWidth: 220,
    backgroundColor: theme.palette.background.paper,
  },
}));

const NestedList = ({
  id,
  handleClick,
  employeeData,
  data = [],
  nestedKey = '',
  listHead,
  children,
  setIsSubProcess,
  setProcessDialogOpen,
}) => {
  const [open, setOpen] = useState({ 0: true });
  const classes = useStyles();
  const [subProcess, setSubProcess] = useState({});
  let { user } = useContext(AuthContext);
  const [selectedIndex, setSelectedIndex] = useState({
    index: 0,
    nestedEl: 0,
  });

  const fetchSubProcessById = async (id) => {
    try {
      const response = await getSubProcess(id);
      setSubProcess(response?.payload?.data[0]);
    } catch (error) { }
  };

  return (
    <Paper style={{ width: '95%' }}>
      <List
        component="nav"
        aria-labelledby={`${id}-subheader`}
        subheader={
          <ListSubheader component="div" id={`${id}-subheader`}>
            {listHead}
            {children}
          </ListSubheader>
        }
        className={classes.root}>
        <Divider />
        {data &&
          data.map((item, index) => (
            <React.Fragment key={`${id}_${index}`}>
              <ListItem
                button
                onClick={() => setOpen({ ...open, [index]: !open[index] })}>
                {(open && open[index]) ?? subProcess.processId === item._id ? (
                  <ExpandLess />
                ) : (
                  <ExpandMore />
                )}
                <ListItemText primary={item.name} />
                {/* {employeeData?.isFlaggedUser ||
                employeeData?.employeeType == 'individual' ? ( */}
                {employeeData.status !== STATUS_TEXT.SUBMITTED && (
                  <Button
                    variant="contained"
                    disableElevation
                    color="success"
                    size="small"
                    startIcon={
                      <i
                        class="fa-solid fa-square-plus"
                        style={{ fontSize: '24px' }}></i>
                    }
                    onClick={() => {
                      setProcessDialogOpen(true);
                      setIsSubProcess(true);
                      user['processName'] = item.name;
                    }}></Button>
                )}
                {/* ) : null} */}
              </ListItem>
              {nestedKey &&
                item[nestedKey] &&
                item[nestedKey].map((nestedItem, nestedIndex) => {
                  return (
                    <Collapse
                      in={
                        (open && open[index]) ??
                        subProcess.processId === item._id
                      }
                      timeout="auto"
                      unmountOnExit
                      key={`${id}_${nestedIndex}`}>
                      <List component="div" disablePadding>
                        <ListItem
                          button
                          onClick={async () => {
                            handleClick({
                              process: item.name,
                              processId: item._id,
                              name: nestedItem.name,
                              subProcessId: nestedItem._id,
                            });
                            setSelectedIndex({
                              index: index,
                              nestedEl: nestedIndex,
                            });
                            await fetchSubProcessById(nestedItem._id);
                          }}
                          selected={
                            selectedIndex.index == index
                              ? nestedIndex == selectedIndex.nestedEl
                              : ''
                          }>
                          <ListItemText
                            className="ml-4"
                            primary={nestedItem.name}
                          />
                        </ListItem>
                      </List>
                    </Collapse>
                  );
                })}
              <Divider />
            </React.Fragment>
          ))}
      </List>
    </Paper>
  );
};

export default NestedList;
