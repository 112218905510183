import { get, post } from '../api_helper';
import * as url from '../url_helper';
import { getApiConfig } from '../auth_helper';

//* get poc by enterprise id
export const getPocOrganizationId = async (id) => {
  return await get(`${url.POC}?enterpriseId=${id}`, getApiConfig());
};

//* get poc by poc id
export const getEnterpriseDataById = async (id) => {
  return await get(`${url.POC}/${id}`, getApiConfig());
};

//* add poc
export const createPoc = async (data) => {
  return await post(`${url.POC}`, data, getApiConfig());
};

//* get poc report
export const getPocEnterpriseReport = async (pocId, collectionEventId) => {

  if (collectionEventId) {
    collectionEventId = `&collectionEventId=${collectionEventId}`;
  } else {
    collectionEventId = '';
  }
  return await get(
    `${url.POC}/getEnterpriseSummaryReport?pocId=${pocId}${collectionEventId}`,
    getApiConfig()
  );
};

//* get poc report
export const getPocRagReport = async (pocId, collectionEventId) => {
  if (collectionEventId) {
    collectionEventId = `&collectionEventId=${collectionEventId}`;
  } else {
    collectionEventId = '';
  }
  return await get(
    `${url.POC}/getRAGSummaryReport?pocId=${pocId}${collectionEventId}`,
    getApiConfig()
  );
};
